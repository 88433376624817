import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function Music() {
  return (
    <section id="music" className="spacey">
      <h2 className="upper centre">Music</h2>
      <ol className="albums">
        <li className="album border reverse">
          <StaticImage
            as="figure"
            src="../images/albums/just-to-please-you.jpg"
            alt="Just to Please You cover"
            objectFit="contain"
          />
          <article className="yellow_border">
            <h3 className="upper">Just to Please You (2023)</h3>
            <figure>
              <blockquote>
                <p>
                  Just to Please You is the group’s first full length album
                  collaboration with Neptune’s Machine Producer Jason Mercer
                  (ani difranco, Ron Sexsmith). Taking inspiration from artists
                  like Wilco and The National, The Gertrudes knit together a
                  sound like an old-time saloon party travelling through deep
                  space. Just to Please You follows four full length albums and
                  extensive touring across the country, including memorable
                  performances like at the Vancouver Folk Festival and the
                  Halifax Pop Explosion, and sharing the stage with artists like
                  Sarah Harmer, Calexico, and Ricky Skaggs.
                </p>
              </blockquote>
            </figure>
            <SinglePlayer url="2053492427/track=2301170817" />
          </article>
        </li>

        <li className="album border">
          <StaticImage
            as="figure"
            src="../images/albums/E2E-cover.jpg"
            alt="Emergency To Emergency cover"
            objectFit="contain"
          />
          <article className="yellow_border">
            <h3 className="upper">Emergency To Emergency (2021)</h3>
            <figure>
              <blockquote>
                <p>
                  We rallied the band back together for another highly
                  collaborative full length recording, Emergency to Emergency
                  (November 26, 2021 on Wolfe Island Records). Based on 19th
                  Century Irish and North American fiddle tunes, this fourth
                  album explores the many “hidden pandemics” that we’re all
                  waking up to these days.
                </p>
              </blockquote>
            </figure>
            <SinglePlayer url="349160480/track=2527888890" />
          </article>
        </li>

        <li className="album border reverse">
          <StaticImage
            as="figure"
            src="../images/albums/neighbourhood-cover.jpg"
            alt="Neighbourhood cover"
            objectFit="contain"
          />
          <article className="pink_border">
            <h3 className="upper">Neighbourhood (2013)</h3>
            <figure>
              <blockquote>
                <p>
                  The economy is a strong subject on this album but also is the
                  need for family and friendship. Imagine Arcade Fire’s The
                  Suburbs crossed with Springsteen’s Wrecking Ball and you may
                  be halfway there.
                </p>
              </blockquote>
              <figcaption>
                —<cite>Exclaim Magazine</cite>
              </figcaption>
            </figure>
            <SinglePlayer url="2659996902/track=779998436" />
          </article>
        </li>

        <li className="album border">
          <StaticImage
            as="figure"
            src="../images/albums/til-the-morning-cover.jpg"
            alt="Till the Morning Shows Her Face to Me cover"
            objectFit="contain"
          />
          <article className="blue_border">
            <h3 className="upper">
              Till the Morning Shows Her Face to Me (2011)
            </h3>
            <figure>
              <blockquote>
                <p>
                  Thoughtful, breezy and with the wonder of Cockburn, the
                  Kingston folkestra sings gently about the remedies that get
                  you through the night.
                </p>
              </blockquote>
              <figcaption>
                —<cite>The Globe & Mail</cite>
              </figcaption>
            </figure>
            <SinglePlayer url="2145982756/track=820340843" />
          </article>
        </li>

        <li className="album border reverse">
          <StaticImage
            as="figure"
            src="../images/albums/dawn-time-riot-cover.jpg"
            alt="Dawn Time Riot cover"
            objectFit="contain"
          />
          <article className="yellow_border">
            <h3 className="upper">Dawn Time Riot (2010)</h3>
            <figure>
              <blockquote>
                <p>
                  Between the mandolins, singalong folk melodies and driving
                  percussion come nods to 70s classic rock, left-field samples
                  and sonic experimentation … Balk at the ambitiousness, cheer
                  at the result.
                </p>
              </blockquote>
              <figcaption>
                —<cite>NOW Magazine</cite>
              </figcaption>
            </figure>
            <SinglePlayer url="2819111608/track=2544616783" />
          </article>
        </li>

        <li className="album border">
          <StaticImage
            as="figure"
            src="../images/albums/hard-water-cover.jpg"
            alt="Hard Water cover"
            objectFit="contain"
          />
          <article className="pink_border">
            <h3 className="upper">Hard Water (2009)</h3>
            <figure>
              <blockquote>
                <p>
                  The most exciting thing about the EP is how The Gertrudes
                  contrast the old time feel of banjos, ukes and accordions with
                  electronics, horns and theremin (sweet) to create modern,
                  spooky tracks that are as thick and ominous, but also as
                  breathtaking as a dense fog over the harbour.
                </p>
              </blockquote>
              <figcaption>
                —<cite>Penguin Eggs Magazine</cite>
              </figcaption>
            </figure>
            <SinglePlayer url="1744291928" />
          </article>
        </li>
      </ol>
    </section>
  );
}

function SinglePlayer({ url }) {
  return (
    <iframe
      height="42"
      title="Bandcamp embed"
      width="100%"
      src={
        "https://bandcamp.com/EmbeddedPlayer/album=" +
        url +
        "/size=small/bgcol=ffffff/linkcol=333333/artwork=none/transparent=true/"
      }
      seamless
    />
  );
}
