import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function News() {
  return (
    <section id="news" className="spacey">
      <h2 className="upper centre">Dates</h2>

      <ol className="stories">
        <li className="story">
          <StaticImage
            as="figure"
            src="../images/gigs/cantabile-gerts.jpg"
            alt="Gertrudes gig"
            objectFit="fill"
            className="border"
          />
          <article>
            <h3>Great Canadian Songbook</h3>
            <p>Songs of Icons</p>
            <p>March 1, 2025</p>
            <p>7:30 PM</p>
            <p>The Spire (82 Sydenham St.)</p>
            <p>
              <a
                class="button"
                href="https://cantabilechoirs.ca/concerts/great-canadian-songbook/">
                More Information
              </a>{" "}
            </p>
          </article>
        </li>

        <li className="story">
          <StaticImage
            as="figure"
            src="../images/gigs/GertrudesxToucanFridays-web.jpg"
            alt="Gertrudes gig"
            objectFit="fill"
            className="border"
          />
          <article>
            <h3>At The Toucan</h3>
            <p>Fridays, Feb 2025</p>
            <p>Feb 07 + 14 + 21</p>
            <p>5-8pm</p>
            <p>No Cover</p>
          </article>
        </li>
      </ol>
    </section>
  );
}
