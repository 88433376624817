import React from "react";

export default function Video() {
  return (
    <section id="video" className="spacey">
      <h2 className="upper centre">Video</h2>
      <ol className="video_list">
        <SingleVideo url="TOzXUW6yQxE" />
        <SingleVideo url="KP_-aUbexS4" />
        <SingleVideo url="_upeYp4Zvh8" />
        <SingleVideo url="eOylv7xqM8Y" />
        <SingleVideo url="6MsNajkOyUQ" />
        <SingleVideo url="y3FTUtmfGf0" />
      </ol>
    </section>
  );
}

function SingleVideo({ url }) {
  return (
    <li>
      <iframe
        width="560"
        height="315"
        src={"https://www.youtube.com/embed/" + url}
        title="YouTube video player"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        allowFullScreen
      />
    </li>
  );
}
