import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function NewAlbum() {
  return (
    <section id="new_album" className="spacey">
      <StaticImage
        as="figure"
        src="../images/albums/city-noise-cover.jpg"
        alt="Just to Please You cover"
        loading="eager"
        objectFit="contain"
      />
      <article className="space">
        <h2 className="upper">
          Latest Album: <i>City Noise</i>
        </h2>
        <p>
          City Noise (March 14, 2025 Wolfe Island Records / Outside Music
          Canada) is The Gertrudes sixth full length album and features special
          guests: Cantabile Children's Choir, Danielle Hope Edwards, James
          Taylor, Grace Dixon (Stucco), Greg Runions, Jonathan "Bunny" Stewart,
          Jon McLurg, Ian Stutt, and co-writing by Katarokwi-Kingston poet
          laureate Sadiqa de Meijer (on "Late One Night"). Produced by Neptune’s
          Machine Jason Mercer (Ani Difranco, Ron Sexsmith).
        </p>

        <a
          href="https://thegertrudes.bandcamp.com/track/city-noise-2"
          className="button yellow_border">
          Hear the title track on Bandcamp
        </a>
      </article>
    </section>
  );
}
